@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Thin"),
        url("/public/fonts/static/geologica/woff2/Geologica_Auto-Thin.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* Extra Light */
@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Extra Light"),
        url("/public/fonts/static/geologica/woff2/Geologica_Auto-ExtraLight.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/* Regular */
@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Regular"),
        url("/public/fonts/static/geologica/woff2/Geologica_Auto-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* Medium */
@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Medium"),
        url("/public/fonts/static/geologica/woff2/Geologica_Auto-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Semi Bold */
@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Semi Bold"),
        url("/public/fonts/static/geologica/woff2/Geologica_Cursive-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Extra Bold */
@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Extra Bold"),
        url("/public/fonts/static/geologica/woff2/Geologica_Auto-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

/* Black */
@font-face {
    font-family: "Geologica";
    src:
        local("Geologica Black"),
        url("/public/fonts/static/geologica/woff2/Geologica_Auto-Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Source code pro";
    src:
        local("Source code black"),
        url("/public/fonts/static/source-code/SourceCodePro-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source code pro";
    src:
        local("Source code extra bold"),
        url("/public/fonts/static/source-code/SourceCodePro-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source code pro";
    src:
        local("Source code bold"),
        url("/public/fonts/static/source-code/SourceCodePro-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source code pro";
    src:
        local("Source code semibold"),
        url("/public/fonts/static/source-code/SourceCodePro-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source code pro";
    src:
        local("Source code medium"),
        url("/public/fonts/static/source-code/SourceCodePro-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sarala";
    src:
        local("Sarala normal"),
        url("/public/fonts/static/sarala/Sarala-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Sarala";
    src:
        local("Sarala bold"),
        url("/public/fonts/static/sarala/Sarala-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src:
        local("Inter regular"),
        url("/public/fonts/static/inter/Inter_28pt-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src:
        local("Inter medium"),
        url("/public/fonts/static/inter/Inter_28pt-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src:
        local("Inter bold"),
        url("/public/fonts/static/inter/Inter_28pt-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src:
        local("Inter extra bold"),
        url("/public/fonts/static/inter/Inter_28pt-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DM Sans";
    src:
        local("DM Sans regular"),
        url("/public/fonts/static/dm-sans/DMSans_24pt-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DM Sans";
    src:
        local("DM Sans medium"),
        url("/public/fonts/static/dm-sans/DMSans_24pt-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DM Sans";
    src:
        local("DM Sans semibold"),
        url("/public/fonts/static/dm-sans/DMSans_24pt-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #efefef;
}

html {
    height: 100%;
}

* {
    scroll-behavior: smooth;
}

img {
    max-width: 100%;
    height: auto;
}

.swiper .swiper-wrapper {
    margin-bottom: 18px;
}

.swiper-slide {
    width: 352px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination {
    position: static !important;
}

.swiper .swiper-pagination-bullet {
    background-color: #01cffe;
    width: 12px;
    height: 12px;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
    position: absolute;
    left: auto;
    right: 0;
    top: 22px;
    height: 40px;
    width: 40px;
}

.swiper .swiper-button-prev {
    right: 56px;
}
